import React, { useEffect, useState } from 'react';
import { motion, useMotionValue } from 'framer-motion';
import './FloatingIsland.css';

const FloatingFrame = ({ src }) => {
  const x = useMotionValue(0); // Track horizontal movement
  const y = useMotionValue(0); // Track vertical movement
  const [isHovered, setIsHovered] = useState(false); // Track hover state

  // Smooth mouse-based movement
  useEffect(() => {
    const handleMouseMove = (e) => {
      const { innerWidth, innerHeight } = window;
      const xPos = (e.clientX / innerWidth - 0.5) * 20; // Adjust to increase/decrease movement range
      const yPos = (e.clientY / innerHeight - 0.5) * 20;
      x.set(xPos);
      y.set(yPos);
    };

    window.addEventListener('mousemove', handleMouseMove); // Track mouse movements
    return () => window.removeEventListener('mousemove', handleMouseMove); // Cleanup on unmount
  }, [x, y]);

  return (
    <motion.div
      className="frame-container"
      onMouseEnter={() => setIsHovered(true)} // Start hover
      onMouseLeave={() => setIsHovered(false)} // End hover
    >
      {/* Floating Image */}
      <motion.img
        src={src}
        alt="Profile"
        className="profile-image"
        style={{ x, y }} // Apply movement to the image
        animate={{ scale: isHovered ? 1.1 : 1 }} // Scale image on hover
        transition={{ type: 'spring', stiffness: 120, damping: 20 }} // Smooth bounce effect
      />

      {/* Name and Description */}
      <div className="name-title">
        <motion.h1
          className="name"
          animate={{ y: isHovered ? -10 : 0 }} // Slight lift on hover
          transition={{ type: 'spring', stiffness: 100, damping: 15 }}
        >
          Joneskim Kimo
        </motion.h1>
        <motion.h2
          className="subtitle"
          animate={{ y: isHovered ? -5 : 0 }} // Subtle lift for subtitle
          transition={{ type: 'spring', stiffness: 80, damping: 10 }}
        >
          I love learning about the universe, building things that help us learn, and turning ideas into reality—all while proudly repping Tanzania!
        </motion.h2>
      </div>
    </motion.div>
  );
};

export default FloatingFrame;
