// src/App.js
import React from 'react';
import './App.css';
import FloatingFrame from './components/FloatingIsland';
import profileImage from './assets/IMG_5685.png';

const App = () => {
  return (
    <div className="app">
      {/* Floating Frame with Image and Name */}
      <FloatingFrame src={profileImage} />
    </div>
  );
};

export default App;
